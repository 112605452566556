import firebase from 'firebase'

// pull the crappy REACT_APP names from the .env file and convert them to the names firebase expects
const {
  REACT_APP_API_KEY:apiKey,
  REACT_APP_AUTH_DOMAIN:authDomain,
  REACT_APP_PROJECT_ID:projectId,
  REACT_APP_STORAGE_BUCKET:storageBucket,
  REACT_APP_MESSENGER_ID:messagingSenderId,
  REACT_APP_APP_ID:appId,
} = process.env

// in es6, creating an object like this will turn the variable names into the keys, and the variable value in the values
const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// allows us to do import firebase from firebase
export default firebase;