import React, { useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import firebase from "../utils/firebase.js";
import { AuthContext } from "../utils/Auth.js";
import Form from "@rjsf/semantic-ui"
import {  Grid, 
  Header,
  Message,
  Button
} from 'semantic-ui-react'
import config from 'react-global-configuration';

const Login = ({ history }) => {

  const loginRedirect = config.get('loginRedirect', '/')

  const [errorMessage, setErrorMessage] = useState(null)

  const schema = {
    title: "",
    type:'object',
    properties:{
      email:{
        type:'string'
      },
      password:{
        type:'string'
      }
    },
    required:['email', 'password']
  }

  const uiSchema = {
    password:{
      "ui:widget":'password'
    }
  }

  const onSubmit = async ({formData}, e) => {
    const { email, password } = formData
    console.log('hey it triggered')
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      history.push(loginRedirect);
    } catch(error){
      console.log(error)
      setErrorMessage(error.message)
    }
  }

  const triggerFormSubmit = async(stuff) => {
    console.log('attempting submi')
    console.log(formReference)
    console.log(stuff)
    // setErrorMessage(null)
    // formReference.submit()
  }

  let formReference;

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to={loginRedirect} />;
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh', padding:'20px', margin:'0px', backgroundColor:'#efefef' }} verticalAlign='middle'>
      <Grid.Row>
      <Grid.Column style={{ maxWidth: 450, backgroundColor:'#d6d6d6', padding:30 }}>
        <p>Hello. We're under private testing right now so no open signups, but you could be a tester by reaching out to me (Jesse) on slack - username "itsjesseyo". I'd also love to hear feedback on feature ideas for this upcoming app. Cheers. </p>
        <Header as='h2' textAlign='center'>
          Login to your account
        </Header>
        <Form schema={schema} onSubmit={onSubmit} uiSchema={uiSchema} ref={ (form) => {formReference = form} }>
          <Button primary style={{marginTop:20, minHeight:50, minWidth:100}} >Login</Button>
        </Form>

        { errorMessage == null ? null : (
          <Message negative>{errorMessage}</Message>
        )}
        
{/*        <Message>
          Don't have an account? <a href='#'>Sign Up</a>
        </Message>*/}
      </Grid.Column>
      </Grid.Row>

    </Grid>
  );
};

export default withRouter(Login);