import React from 'react'
import {Redirect} from 'react-router-dom';
import {
  Button,
} from 'semantic-ui-react'
import firebase from "../../utils/firebase.js";



const SignOutButton = ({ history }) => {

  const signout = async () => {
    console.log('signing out')
    await firebase.auth().signOut()
    return <Redirect to='/login' />
  }

  return (
    <form>
      <Button onClick={signout} >Sign Out </Button>
    </form>
  )

}

export default SignOutButton