import React, { useState, useEffect, useContext } from 'react'
import firebase from '../../utils/firebase'
import Form from "@rjsf/semantic-ui"
import styles from './TutorialsCRUD.module.css'
import {  
  Grid, 
  List,
  Segment,
  Button,
  Dimmer,
  Loader,
  Icon,
  Header,
  Embed,
  Card,
} from 'semantic-ui-react'
import {print, wait} from '../../utils/jesse.js'
import cryptoRandomString from 'crypto-random-string';

const YoutubeCard = (props) => {

  const {tutorial} = props

  return (
    <Card centered fluid>
      <Embed id={tutorial.url} source='youtube'  />
      <Card.Content>
        <Card.Header> {tutorial.name} </Card.Header>
        <Card.Description> {tutorial.description} </Card.Description>
      </Card.Content>
    </Card>
  )


}

const TutorialsList = (props) => {
  const {tutorials} = props

  const tutorialsElements = tutorials.map((tutorial) => {
    switch(tutorial.type){
      case 'youtube':
        return <YoutubeCard tutorial={tutorial} />
      default:
        return <></>
    }
  })

  return (
    <>
      {tutorialsElements}
    </>
  )
}

const TutorialsCRUD = ( props) => {

  const { itemID } = props
  const [showLoader, setShowLoader] = useState(false)
  const [showThumbsUp, setShowThumbsUp] = useState(false)

  const [showForm, setShowForm] = useState(false)
  const [inputData, setInputData] = useState(null)

  const [tutorials, setTutorials] = useState([])

  useEffect( () => {

    const fetchData = async () => {
      const db = firebase.firestore()
      const tutorialsRef = db.collection("tutorials")
      print({itemID})
      const query = tutorialsRef.where('itemID', '==', itemID)
      let data = []
      query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const tutorial = {...doc.data(), id:doc.id}
          data.push(tutorial)
        })
        setTutorials(data)
      })

      // print(docs)

      // if(docs.exists === false){
      //   console.log('creating new entry')
      //   // await tutorialsRef.doc(itemID).set({})
      //   // docs = await query.get()
      // }

      // const data = docs.data()

      // print(data)

      // setItem({...data, id:doc.id})
    }
    if(itemID){
      print(itemID)
      fetchData()
    }
  }, [itemID])

  const onPlus = () => {
    setShowForm(true)
  }

  const onSubmit = async ({formData}, e) => {
    setShowLoader(true)

    formData.itemID = itemID
    print(formData)
    const db = firebase.firestore()
    await db.collection('tutorials').doc(cryptoRandomString({length: 34, type: 'alphanumeric'})).set(formData)
    wait(1000, setShowLoader, false)
    wait(1000, setShowThumbsUp, true)
    wait(2500, setShowThumbsUp, false)
    wait(2500, setShowForm, false)
  }

  const onChange = ({formData}) => {
    setInputData(formData)
  }

  let saveButtonValue = 'save'
  if(showThumbsUp){
    saveButtonValue = <Icon name='thumbs up outline' size='large' />
  }
  

  const formData = inputData || {}


  const tutorialSchema = {
    type:'object',
    properties: {
      name:{
        type:'string'
      },
      type:{
        type:'string',
        enum:['webpage', 'youtube', 'pdf'],
      },
      url:{
        type:'string'
      },
      description:{
        type:'string'
      },
    },
    uiSchema:{
      'ui:order':[ 'name', 'type', 'url', 'description'],
      description: {
        'ui:widget':'textarea',
      },
      type:{
        "ui:placeholder": "Tutorial Type",
      },
      url:{
        "ui:placeholder": "link to youtube video",
      }
    }
  }

  let formElement = <></>
  if(showForm){
    formElement = (
      <Form schema={tutorialSchema} uiSchema={tutorialSchema.uiSchema} formData={formData} onSubmit={onSubmit} onChange={onChange} autocomplete='false' >
        <Button loading={showLoader} primary style={{marginTop:60, minHeight:50, minWidth:100}} size='huge'>
          {saveButtonValue}
        </Button>
      </Form>
    )
  }

  return (
    <Segment>
      <Grid textAlign='center' style={{ padding:'20px', margin:'0px' }} verticalAlign='middle'>
        <Grid.Row>
        <Grid.Column style={{ maxWidth: 450, paddingBottom:'100px' }}>
          <Button primary onClick={onPlus} ><Icon name='plus' /></Button>
          <Header as='h1'>Tutorials</Header>
          {formElement}
        </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column style={{ maxWidth: 450, paddingBottom:'100px' }}>
          <TutorialsList tutorials={tutorials} />
        </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )


}

export default TutorialsCRUD