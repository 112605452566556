import React, { useState, useEffect } from 'react'
// import './App.css';
import firebase from './utils/firebase'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ItemCRUD from './components/ItemCRUD/ItemCRUD.js' 

import SchemaContext from './utils/SchemaContext.js'
import EntitiesContext from './utils/EntitiesContext.js'

import { AuthProvider } from "./utils/Auth";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp.js";
import config from 'react-global-configuration'
import configuration from './utils/config.js'
config.set(configuration, { freeze: false })

function App() {

  const [itemSchema, setItemSchema] = useState({})
  const [entities, setEntities] = useState([])


  // gets the base Item Schema
  useEffect( () => {
    
    const fetchData = async () => {
      const db = firebase.firestore()
      const itemsRef = db.collection("schemas")
      const docRef = itemsRef.doc('baseItemSchema')
      let doc = await docRef.get()

      if(doc.exists === false){
        console.log('missing base itemSchema')
      }

      const data = doc.data()
      setItemSchema({...data, id:doc.id})
    }

    fetchData()
  }, [])

  // gets all the entities for seaching
  useEffect( () => {
    
    const fetchData = async () => {
      const db = firebase.firestore()
      const entitiesRef = await db.collection("entities").get()
      const data = entitiesRef.docs.map( (doc) => {
        return {...doc.data(), id:doc.id} 
      })
      setEntities(data)
    }

    fetchData()
  }, [])


  return (
    <AuthProvider>
    <EntitiesContext.Provider value={entities} >
    <SchemaContext.Provider value={itemSchema} >
      <Router>
        <Switch>
          <Route path='/signup' component={SignUp} />
          <Route path='/login' component={Login} />
          <PrivateRoute path='/:itemID' component={ItemCRUD} />
        </Switch>
      </Router>
    </SchemaContext.Provider>
    </EntitiesContext.Provider>
    </AuthProvider>
  );
}

export default App;
