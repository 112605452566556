import React, { useState, useEffect, useContext } from 'react'
import firebase from '../../utils/firebase'
import '@firebase/storage';
import Form from "@rjsf/semantic-ui"
import SchemaContext from '../../utils/SchemaContext.js'
import EntitiesContext from '../../utils/EntitiesContext.js'
import Fuse from 'fuse.js'
import styles from './ItemCRUD.module.css'
import SignOutButton from '../SignOutButton/SignOutButton'
import {  
  Grid, 
  List,
  Segment,
  Button,
  Dimmer,
  Loader,
  Icon,
  Input,
  Image,
} from 'semantic-ui-react'
import {
  print,
  wait,
} from '../../utils/jesse.js'
import TutorialsCRUD from '../TutorialsCRUD/TutorialsCRUD.js'

// https://react-jsonschema-form.readthedocs.io/en/latest/quickstart/

// https://firebase.google.com/docs/firestore/query-data/get-data

const AutoCompleteBox = (props) => {

  const {options, onSelect, selectedField} = props

  if(options && options.length > 0 && selectedField === 'name'){
    return (
      <Segment inverted className={styles.autoCompleteBox} >
        <List divided inverted relaxed>
            {
              options.map(option => (
                <List.Item>
                  <List.Content onClick={() => onSelect(option.item)} >{`${option.item.name}, ${option.item.make}`}</List.Content>
                </List.Item>
              ))
            }
        </List>
      </Segment>
    )
  }else{
    return <></>
  }


}


function slugify(text){
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}


const ItemCRUD = ( props) => {

	const { itemID } = props.match.params

  const itemSchema = useContext(SchemaContext)
  const entities = useContext(EntitiesContext)

  const [item, setItem] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [showThumbsUp, setShowThumbsUp] = useState(false)

  const [selectedField, setSelectedField] = useState(null)
  const [selectedFieldID, setSelectedFieldID] = useState(null)

  const [searchResults, setSearchResults] = useState([])

  const [inputData, setInputData] = useState(null)
  const [newFeaturedImage, setNewFeaturedImage] = useState(null)

	useEffect( () => {

    const fetchData = async () => {
      const db = firebase.firestore()
      const itemsRef = db.collection("items")
      const docRef = itemsRef.doc(itemID)
      let doc = await docRef.get()

      if(doc.exists === false){
        console.log('creating new entry')
        await itemsRef.doc(itemID).set({})
        doc = await docRef.get()
      }
      const data = doc.data()

      setItem({...data, id:doc.id})
    }

    fetchData()
  }, [itemID])


	const onSubmit = async ({formData}, e) => {
    setShowLoader(true)
    if(newFeaturedImage){
      formData['featuredImage'] = newFeaturedImage
    }
		const db = firebase.firestore()
		await db.collection('items').doc(item.id).set(formData)
    wait(1000, setShowLoader, false)
    wait(1000, setShowThumbsUp, true)
    wait(2500, setShowThumbsUp, false)
    
	}

  const onFocus = (id) => {
    setSelectedFieldID(selectedFieldID)
    setSelectedField(id.replace('root_', ''))
  }

  const onChange = ({formData}) => {
    console.log(selectedField)

    let results = []

    if(selectedField in formData && formData[selectedField] != undefined && formData[selectedField].length > 2){
      const fuse = new Fuse(entities, {
        keys: ['name', 'make', 'model']
      })
      // console.log(fuse.search('laser'))

      results = fuse.search(formData[selectedField])
      if(results.length > 3){
        results = results.slice(0,3)
      }
      setSearchResults(results)
    }else{
      // setSearchResults([])
    }

    setInputData(formData)
  }

  const onSelectAutoFill = (item) => {
    let temp = Object.assign({}, inputData, item)
    setInputData(temp)
    setSearchResults([])
  }

  const uploadImage = async (e) => {
    console.log(e)
    const files = e.target?.files || []
    if(files.length === 0){
      return
    }
    const file = files[0]
    print(file)

    try{
      const storagePathAndFilename = `items/${itemID}/featured_images/${slugify(file.name)}`
      print(storagePathAndFilename)
      const ref = firebase.storage().ref(storagePathAndFilename)
      await ref.put(file)
      const downloadURL = await ref.getDownloadURL()
      console.log(downloadURL)
      console.log(formData)
      formData['featuredImage'] = downloadURL
      const db = firebase.firestore()
      await db.collection('items').doc(item.id).set(formData)
      setNewFeaturedImage(downloadURL)
    }catch{

    }

  }


  //move most of this to the schema, fool
  if(itemSchema.title){
    itemSchema.title = `item : ${itemID}`
    itemSchema.uiSchema.notes = {
      'ui:widget':'textarea',
    }
    itemSchema.uiSchema.status = {
      "ui:placeholder": "Status",
    }
    itemSchema.uiSchema.mobility = {
      "ui:placeholder": "Mobility",
    }
    itemSchema.uiSchema.footPrintLength = {
      "ui:placeholder": "inches",
    }
    itemSchema.uiSchema.footPrintDepth = {
      "ui:placeholder": "inches",
    }
    itemSchema.uiSchema.footPrintHeight = {
      "ui:placeholder": "inches",
    }
    itemSchema.uiSchema.usageLeft = {
      "ui:placeholder": "inches",
    }
    itemSchema.uiSchema.usageRight = {
      "ui:placeholder": "inches",
    }
    itemSchema.uiSchema.usageFront = {
      "ui:placeholder": "inches",
    }
    itemSchema.uiSchema.usageBack = {
      "ui:placeholder": "inches",
    }
    itemSchema.uiSchema.usageTop = {
      "ui:placeholder": "inches",
    }
    itemSchema.uiSchema.usageBottom = {
      "ui:placeholder": "inches",
    }
  }

  let saveButtonValue = 'save'
  if(showThumbsUp){
    saveButtonValue = <Icon name='thumbs up outline' size='large' />
  }
  

  const formData = inputData || item

  const featuredImage = () => {
    console.log('featured iamge')
    if(item.featuredImage){
      return <Image src={item.featuredImage} />
    }
    return null
  }

	return (
    <>
    <Segment>
      <AutoCompleteBox options={searchResults} onSelect={onSelectAutoFill} selectedField={selectedField} />
      <Grid textAlign='center' style={{ padding:'20px', margin:'0px' }} verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column>
          {featuredImage()}
          <Input type="file" accept="image/x-png,image/jpeg,image/gif"  onChange={uploadImage} />
        </Grid.Column>
      </Grid.Row>
        <Grid.Row>
        <Grid.Column style={{ maxWidth: 450, paddingBottom:'100px' }}>
            <Form schema={itemSchema} uiSchema={itemSchema.uiSchema} formData={formData} onSubmit={onSubmit} onFocus={onFocus} onChange={onChange} autocomplete='false' >
              <Button loading={showLoader} primary style={{marginTop:60, minHeight:50, minWidth:100}} size='huge'>
                {saveButtonValue}
              </Button>
            </Form>
        </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SignOutButton />
          </Grid.Column>
        </Grid.Row>
  		</Grid>
    </Segment>
    <TutorialsCRUD itemID={itemID} />
    </>
	)


}

export default ItemCRUD